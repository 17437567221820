// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

import GLightbox from 'glightbox';
window.GLightbox = GLightbox;

import Splide from '@splidejs/splide';
window.Splide = Splide;

import Toastr from 'toastr2';
window.toastr = new Toastr;

import dayJs from 'dayjs';
window.dayjs = dayJs;

import 'dayjs/locale/ro';
window.dayjs.locale('ro');

import customParseFormat from 'dayjs/plugin/customParseFormat';
window.dayjs.extend(customParseFormat);

import relativeTime from 'dayjs/plugin/relativeTime';
window.dayjs.extend(relativeTime);

import localizedFormat from 'dayjs/plugin/localizedFormat';
window.dayjs.extend(localizedFormat);

import localeData from 'dayjs/plugin/localeData';
window.dayjs.extend(localeData);

import weekday from 'dayjs/plugin/weekday';
window.dayjs.extend(weekday);

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
window.dayjs.extend(isSameOrBefore);

import businessAdd from './business-add';
window.dayjs.extend(businessAdd);

import flatpickr from 'flatpickr';
window.flatpickr = flatpickr;

import AirDatepicker from 'air-datepicker';
window.AirDatepicker = AirDatepicker;

import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;

import Alpine from 'alpinejs';
import Focus from '@alpinejs/focus';
import Collapse from '@alpinejs/collapse';
import Persist from '@alpinejs/persist';

Alpine.plugin(Focus);
Alpine.plugin(Collapse);
Alpine.plugin(Persist);

Alpine.data('cartItemData', (id, itemQuantity, skuQuantity, skuPackingUnit, route, currentRoute) => ({
    submitting: Boolean(false),
    route: route,
    currentRoute: currentRoute,
    step: skuPackingUnit,
    quantity: itemQuantity,
    initialQuantity: itemQuantity,
    minQty: skuPackingUnit,
    maxQty: skuQuantity,
    init() {
        this.$watch('quantity', value => {
            this.$refs.itemButtons.classList.toggle('invisible', value == this.initialQuantity);
            this.$refs.itemButtons.disabled = value == this.initialQuantity;
            this.$refs.decrementBtn.disabled = value <= this.minQty;
            this.$refs.incrementBtn.disabled = value>= this.maxQty;
        });
    },
    decrementQuantity() {
        if (this.quantity - this.step >= this.minQty) {
            this.quantity = this.quantity - this.step;
        }
    },
    incrementQuantity() {
        if (this.quantity + this.step <= this.maxQty) {
            this.quantity = this.quantity + this.step;
        }
    },
    updateCartItem() {
        this.submitting = Boolean(true);

        fetch(this.route, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                _method: 'PATCH',
                return_route: this.currentRoute,
                item_id: id,
                quantity: this.quantity
            })
        })
        .then(response => response.json())
        .then(() => {
            window.navigation.reload();
        })
        .finally(() => {
            this.submitting = Boolean(false);
        });
    },
    deleteCartItem() {
        this.submitting = Boolean(true);
        fetch(this.route, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                _method: 'DELETE',
                return_route: this.currentRoute,
                item_id: id,
            })
        })
        .then(response => response.json())
        .then(data => {
            location.assign(data.data.url);
        })
        .finally(() => {
            this.submitting = Boolean(false);
        });
    }
}));

Alpine.data('emptyCart', () => ({
    submitting: Boolean(false),
    submitData(url, cartId) {
        this.submitting = Boolean(true);

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                _method: 'DELETE',
                id: cartId,
            })
        })
        .then(response => response.json())
        .then(data => {
            location.assign(data.data.url);
        })
        .finally(() => {
            this.submitting = false;
        });
    }
}));

Alpine.data('visibleNavHighlighter', () => ({
    headings: undefined,
    visibleHeadingId: null,

    init() {
        // Grab all the headings inside the main container.
        this.headings = document.querySelectorAll('[listen-for-intersection-of-titles] h2:not([no-sidebar])');

        this.assignHeadingIds();

        this.onScroll();
    },

    assignHeadingIds() {
        // If a heading doesn't have an [id], we'll give it one
        // based on its text content.
        this.headings.forEach(heading => {
            if (heading.id) return;

            heading.id = heading.textContent.replace(/\s+/g, '-').toLowerCase();
        })
    },

    onScroll() {
        // We're gonna highlight the first heading above an imaginary
        // drawn horizontally across the center of the screen.

        // Get the distance from the top of that line.
        let relativeTop = 3 * window.innerHeight / 4;

        let headingsByDistanceFromTop = {};

        // Populate an object of headings by their distance from our
        // imaginary lines as the keys.
        this.headings.forEach(heading => {
            headingsByDistanceFromTop[heading.getBoundingClientRect().top - relativeTop] = heading;
        });

        // Grab the first one that is above that line.
        let closestNegativeTop = Math.max(...Object.keys(headingsByDistanceFromTop).filter(top => top < 0));

        // If we couldn't find one, don't highlight anything.
        if (closestNegativeTop >= 0 || [Infinity, NaN, -Infinity].includes(closestNegativeTop)) return this.visibleHeadingId = null;

        // Otherwise, highlight that heading.
        this.visibleHeadingId = headingsByDistanceFromTop[closestNegativeTop].id;
    },
}));

window.Alpine = Alpine;
Alpine.start();
